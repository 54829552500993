import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 13; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/20-sep-1/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function AtulyaBharat() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          CLASS V PRESENTATION - ATULYA BHARAT, AMULYA BHARAT
        </h1>

        <p>
          An incredible moment of learning and inspiration unfolded on 1st
          September 2023 on the grounds of Chinmaya Vidyalaya when the students
          of class V presented the glorious achievements of India by means of a
          stage presentation.A scintillating dance drama highlighted the
          important scientific inventions that India has contributed to the
          world.The performances kindled love and patriotism for the country
          when it reminisced about the great inventions of some Indian
          personalities because of which space travel and the study of
          mathematics and surgery is possible today. The Primary Headmistress,
          Ms Archana Gaur, thanked the gathering for their presence.The
          Principal of the school, Ms Archana Soni, addressed the gathering with
          her profound words and praised the efforts of the children.The event
          promoted a strong sense of national identity and extended an emphatic
          message of the progressive future of our country.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
